
//Demo
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://162.19.146.134:1288/api/web/";
// const IMG_URL = "http://162.19.146.134:1288/uploads/images/";
// const LEAD_URL = "http://162.19.146.134:1288/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://162.19.146.134:1288/";

//local
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.12.8:5022/api/web/";
// const IMG_URL = "http://192.168.12.8:5022/uploads/images/";
// const LEAD_URL = "http://192.168.12.8:5022/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.12.8:5022/";

//live
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.mycarryfour.com/node_api/web/";
const IMG_URL = "https://admin.mycarryfour.com/node_api/uploads/images/";
const LEAD_URL = "https://admin.mycarryfour.com/node_api/web/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='903's
const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
const BASE = "https://admin.mycarryfour.com/";





export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY,
   LEAD_URL
}


