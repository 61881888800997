import React, { useState } from 'react';
import style from './history.module.scss';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Invoice from './Invoice';
import DataTable from 'react-data-table-component';
const HistoryContent = (props) => {
    console.log("🚀 ~ HistoryContent ~ props:", props)
    const { currentTheme } = useSelector((state) => state.Theme);
    const { t } = useTranslation();
    const currency = useSelector(state => state.curr);
    const [state, setState] = useState({
        modalShow: false,
        modalData: {}
    })

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modalShow: false,
            modalData: {}
        }))
    };
    const handleShow = (data) => {
        setState(prev => ({
            ...prev,
            modalShow: true,
            modalData: data
        }))
    };
    const handlePerRowsChange = (perPage, inPage) => {
        props.paginationHandler(inPage, perPage);
    };
    const handlePageChange = (inPage) => {
        console.log('--------------',inPage, props.perPage);
        props.paginationHandler(inPage, props.perPage);
    };

    const columns = [
        {
            name: t('Common.orderId'),
            selector: row => row.order_id,
        },
        {
            name: t('Common.memberName'),
            selector: row => row.user_name,
        },
        {
            name: t('Common.totalAmount'),
            selector: (row) => row.total,
            cell: (row) => (
                <span className={`${style.balanceAmount} ${style.badge}`}>
                    {currency.currentCurr}{" "}
                    {(row.total * currency.value).toFixed(currency.precision)}
                </span>
            ),
        },

        {
            name: t('Common.paymentMethod'),
            selector: row => row.payment_method,
        },
        {
            name: t('Common.orderDate'),
            selector: row => moment(row.date_added).format('MM-DD-YYYY'),
        },
        {
            name: t('Common.action'),
            // selector: row => row.action,
            cell: (row) => (
                <span className={style.actionIcon} onClick={() => handleShow(row)}>
                    <i className="fa fa-eye"></i>
                </span>
            ),
        },
    ];


    return (
        <div className={`${currentTheme == "theme-dark"
            ? `${style.dark_mode_container}`
            : `${style.container}`
            }`}>
            {/* <Table responsive bordered>
                <thead>
                    <tr>
                        <th>{t('Common.orderId')}</th>
                        <th>{t('Common.memberName')}</th>
                        <th>{t('Common.totalAmount')}</th>
                        <th>{t('payout.paymentMethod')}</th>
                        <th>{t('Common.orderDate')}</th>
                        <th>{t('Common.action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.length === 0 ?
                            <td colSpan="13" align="center">No Order</td> :
                            props.data.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.order_id}</td>
                                    <td>{item.user_name}</td>
                                    <td>{currency.currentCurr} {(parseInt(item.total) * currency.value).toFixed(currency.precision)}</td>
                                    <td>{item.payment_method}</td>
                                    <td>{moment(item.date_added).format('MM-DD-YYYY')}</td>
                                    <td className="text-center">
                                        <span className={style.actionIcon} onClick={() => handleShow(item)}>
                                            <i className="fa fa-eye"></i>
                                        </span>
                                    </td>
                                </tr>
                            })
                    }
                    <tr>
                    </tr>
                </tbody>
            </Table> */}


            <DataTable
                columns={columns}
                data={props.data}
                highlightOnHover
                // progressPending={ContentData.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                paginationTotalRows={props.totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t("Common.noDataRecordsToDisplay")}
                responsive
            />

            {state.modalData &&
                <Invoice
                    show={state.modalShow}
                    handleClose={handleClose}
                    data={state.modalData}
                    currency={currency}
                />}
        </div>
    );
}

export default HistoryContent;