import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
//components
import { ProfileImage, ChangePassword } from "./";
import style from "./profile.module.scss";
import {
  UserIcon,
  UserGroup,
  ArrowLeft,
  ArrowRight,
} from "../../../assets/icons";
import AlertMessage from "../common/AlertMessage";
import { useSelector } from "react-redux";

function Content(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };
  //state
  const [state, setState] = useState({
    modal: {
      show: false,
      modalType: "",
      icon: "",
    },
    notify: notifyInintialState,
    isSuccess: false,
  });

  const history = useHistory();

  useEffect(() => {}, [state.modal.type, props]);

  //state variable
  const { modal } = state;

  //changepassowrd button click
  const changeButtonClick = (modalType) => {
    let icon = "fa fa-key";
    if (modalType === "changePassword") {
      icon = "fa fa-lock";
    }
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: true,
        modalType: modalType,
        icon: icon,
      },
    }));
  };

  const invoiceHandler = ()=>{
    history.push('/registerinvoice')
  }

  //onclose model
  const closeModal = () => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: false,
        modalType: "",
      },
      isSuccess: false,
    }));
  };

  //on dismiss notify
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      notify: notifyInintialState,
    }));
  };

  //show totify message
  const messageShow = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: type === "success" ? false : true,
      },
      isSuccess: type === "success" ? true : false,
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: header,
        message: message,
      },
    }));
  };
  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: type === "success" ? false : type === "error" ? false : true,
      },
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };

  

  return (
    <div className={style.ProfileDetails}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_panel}`
            : `${style.panel}`
        }`}
      >
        <div className={style.profileUser}>
          <div className={style.ProfileSection}>
            <div className={style.MainProfileDetails}>
              <ProfileImage
                image={props.profile.user_photo}
                date={props.profile?.membership_package?.product_validity
                  ?.date}
                notify={showNotify}
                getData={props.getData}
                onDeletePic={props.onDeletePic}
              />
              <h3
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_ProfileFullName}`
                    : `${style.ProfileFullName}`
                }`}
              >
                {props.profile.full_name}
              </h3>
              <h5
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_ProfileUserName}`
                    : `${style.ProfileUserName}`
                }`}
              >
                {props.profile.user_name}
              </h5>
              <p
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_UserEmail}`
                    : `${style.UserEmail}`
                }`}
              >
                {props.profile.email}
              </p>
              <span
                onClick={() => {
                  changeButtonClick("changePassword");
                }}
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_PasswordButton}`
                    : `${style.PasswordButton}`
                }`}
              >
                {t("Common.changePassword")}
              </span>
              <span
                onClick={() => {
                  changeButtonClick("changeTransactionPassword");
                }}
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_PasswordButton}`
                    : `${style.PasswordButton}`
                }`}
              >
                {t("Common.changeTransactionPassword")}
              </span>
              {props.profile.hasOwnProperty("kyc_status") && (
                <div
                  className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_kyc}`
                      : `${style.kyc}`
                  }`}
                >
                  <div>
                    {t("Common.kyc")} :
                    {props.profile.kyc_status ? (
                      <span className={style.Verified}>
                        {" "}
                        {t("Common.verified")}
                      </span>
                    ) : (
                      <span className={style.notVerified}>
                        {" "}
                        {t("Common.notverified")}
                      </span>
                    )}
                  </div>
                  {/* <button> */}
                  <Link to="/kyc_upload" className={style.KycLink}>
                    {t("Common.moreinfo")}
                  </Link>
                  {/* </button> */}
                </div>
              )}
            </div>
          </div>
          <div className={style.ExtraDetails}>
            {props.profile.rank && (
              <h6
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_rankText}`
                    : `${style.rankText}`
                }`}
              >
                {t("Common.rank")} :{" "}
                <span
                  className={`${style.rankName}`}
                  style={{ color: props.profile.rank.rank_color }}
                >
                  {props.profile.rank.curent_rank}
                </span>
              </h6>
            )}
            <div className={style.packageRenewal}>
              {props.profile.membership_package && (
                <>
                  {/* <div className={style.package}>
                    <p
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_Packagetext}`
                          : `${style.Packagetext}`
                      }`}
                    >
                      {t("Dashboard.membershipPackage").toUpperCase()}
                    </p>
                   
                    <p
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_packageName}`
                          : `${style.packageName}`
                      }`}
                    >
                      {props.profile.membership_package.name}
                    </p>
                    {(props.profile.membership_package.upgrade_link ||
                      props.profile.membership_package.upgrade_link === "") && (
                      <div className={style.packageButton}>
                        {props.profile.membership_package.upgrade_link
                          .length === 0 ? (
                          <Link
                            to="/upgrade_package"
                            className={style.renewButton}
                          >
                            {t("Dashboard.upgradeMembership")}
                          </Link>
                        ) : (
                          <a
                            href={props.profile.membership_package.upgrade_link}
                            className={style.renewButton}
                          >
                            {t("Dashboard.upgradeMembership")}
                          </a>
                        )}
                      </div>
                    )}
                  </div> */}
                  <div className={style.renew}>
                    {props.profile.membership_package.product_validity && (
                      <>
                        <p
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_ExpiryText}`
                              : `${style.ExpiryText}`
                          }`}
                        >
                          {t("Dashboard.membershipExpiry")}
                        </p>
                        <p
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_ExpiryDate}`
                              : `${style.ExpiryDate}`
                          }`}
                        >
                          {
                            props.profile.membership_package.product_validity
                              .date
                          }
                        </p>
                        {(props.profile.membership_package.product_validity
                          .renewal_link ||
                          props.profile.membership_package.product_validity
                            .renewal_link === "") ? (
                          <div className={style.renewButtons}>
                            {props.profile.membership_package.product_validity.status &&
                            (props.profile.membership_package.product_validity
                              .renewal_link.length === 0 ? (
                              <Link
                                to="/upgrade_validity"
                                className={style.renewButton}
                              >
                                {" "}
                                {t("Dashboard.renewMembership")}
                              </Link>
                            ) : (
                              <a
                                href={
                                  props.profile.membership_package
                                    .product_validity.renewal_link
                                }
                                className={style.renewButton}
                              >
                                {t("Dashboard.renewMembership")}
                              </a>
                            ))}
                          </div>
                        ):(<div className={style.toggle_btn_profile}>
                            { props.checked ? <label className={style.switch} for="checkbox">
                                <input type="checkbox" id="checkbox"  checked={props.checked}  onClick={props.handleSubscription} />
                                <div className={style.slider}></div>
                              </label>: ""}         
                          </div>)}
                      </>
                    )}
                  </div>
                  
                </>
              )}
            </div>
            <div className={style.sponsorPlacement}>
              {props.extraData &&
                props.extraData.placement.map((items, index) => {
                  return (
                    <div key={index}>
                      <p
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_textheader}`
                            : `${style.textheader}`
                        }`}
                      >
                        {t("Common." + items.text)}
                      </p>
                      <p
                        style={{ textTransform: "capitalize" }}
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_textvalue}`
                            : `${style.textvalue}`
                        }`}
                      >
                        <span>{items.head}</span>
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className={style.UserDetails}>
              {props.extraData.pv &&
                props.extraData.pv.map((items, index) => {
                  return (
                    <div
                      className={`d-flex ${style.PersonalDetails} ${
                        index !== props.extraData.pv.length - 1 &&
                        style.verticalLine
                      }`}
                      key={index}
                    >
                      {items.text === "personalPv" && (
                        <div className={`${style.Icon} ${style.bgPurple}`}>
                          {" "}
                          <UserIcon />{" "}
                        </div>
                      )}
                      {items.text === "groupPV" && (
                        <div className={`${style.Icon} ${style.bgyellow}`}>
                          {" "}
                          <UserGroup />{" "}
                        </div>
                      )}
                      {/* {items.text === "leftCarry" && (
                        <div className={`${style.Icon} ${style.bgskyblue}`}>
                          {" "}
                          <ArrowLeft />{" "}
                        </div>
                      )}
                      {items.text === "rightCarry" && (
                        <div className={`${style.Icon} ${style.bglight}`}>
                          {" "}
                          <ArrowRight />{" "}
                        </div>
                      )} */}
                      <div>
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_userTitle}`
                              : `${style.userTitle}`
                          }`}
                        >
                          {t("Common." + items.text)}
                        </div>
                        <div className={`${style.userHead} `}>{items.head}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <ChangePassword
        {...modal}
        onClose={closeModal}
        setNotify={messageShow}
        showNotify={showNotify}
        policy={props.profile.password_policy}
      />
      {state.notify.show && (
        <AlertMessage
          type={state.notify.type}
          message={state.notify.message}
          show={state.notify.show}
          dismiss={notifyDissmissed}
        />
      )}
      {/* <SuccessModal 
                show={state.isSuccess}
                closeModal={closeModal}
            /> */}
    </div>
  );
}

export default Content;
